<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="banner">
            <h3 class="box-heading">About Us</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="about-us">
          <div class="row">
            <div class="col-md-8">

              <div class="content">
                <section class="who-we-are">
                  <h2><i class="fas fa-users"></i> Who We Are</h2>
                  <p>
                    We believe that raising a child is a team effort. With a strong team
                    supporting them, every child can learn, be well, and thrive.
                  </p>
                  <p>
                    Parents and caregivers are the most powerful, capable, and important
                    members of a child's team. However, all too often, parents have to
                    support their child's wellbeing alone.
                  </p>
                  <p>
                    Our comprehensive model brings together all the adults in a child's
                    life, including parents, educators, and medical and mental health
                    providers, starting from a very early age.
                  </p>
                </section>
                <section class="our-vision">
                  <h2><i class="fas fa-eye"></i> Our Vision</h2>
                  <p>
                    Our vision is to build a model that can be effectively implemented in
                    diverse communities and sustained on public funding.
                  </p>
                </section>

              </div>
            </div>
            <div class="col-md-4">

              <section class="our-team">
                <h2><i class="fas fa-chalkboard-teacher"></i> Our Team</h2>
                <p>
                  We are a diverse team with extensive experience in early childhood
                  education, K-12 education, family support, and health care.
                </p>
<!--                <div class="team-photo" aria-live="polite">-->
<!--                  <div class="team-slider">-->
<!--                    <div v-for="member in displayedMembers" :key="member.alt" class="slider-item">-->
<!--                      <img :src="member.src" :alt="member.alt" width="150" height="150">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="team-buttons">-->
<!--                    <button @click="prevSlide" :disabled="isFirstSlide" aria-label="Previous slide">Prev</button>-->
<!--                    <button @click="nextSlide" :disabled="isLastSlide" aria-label="Next slide">Next</button>-->
<!--                  </div>-->
<!--                </div>-->
              </section>
              <section class="who-we-serve">
                <h2><i class="fas fa-users-cog"></i> Who We Serve</h2>
                <p>
                  We currently operate two program sites in East Palo Alto and East Bay,
                  California. Our school program supports a child's health, academic,
                  and social-emotional development from infancy through early adolescence.
                </p>
              </section>

            </div>
            <div class="col-md-12">
              <section class="our-gallery">
                <h2><i class="fas fa-images"></i> Our Gallery</h2>
                <div class="image-slider" aria-live="polite">
                  <div class="slider-item">
                    <img :src="displayedImage.src" :alt="displayedImage.alt" height="450" width="750">
                  </div>
                  <div class="dots">
              <span
                  v-for="(image, index) in images"
                  :key="index"
                  class="dot"
                  :class="{ active: currentSlide === index }"
                  @click="currentSlide = index"
                  aria-label="Go to slide {{ index + 1 }}"
              ></span>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      currentSlide: 0,
      teamMembers: [
        {src: require('../assets/images/teacher.jpg'), alt: 'Team Member 1'},
        {src: 'https://via.placeholder.com/150?text=Team+Member+2', alt: 'Team Member 2'},
        {src: 'https://via.placeholder.com/150?text=Team+Member+3', alt: 'Team Member 3'},
        {src: 'https://via.placeholder.com/150?text=Team+Member+4', alt: 'Team Member 4'},
        {src: 'https://via.placeholder.com/150?text=Team+Member+5', alt: 'Team Member 5'},
      ],
      images: [
        {src: require('../assets/images/teacher.jpg'), alt: 'Image 1'},
        {src:  require('../assets/images/IMG-20241009-WA0002.jpg'), alt: 'Image 2'},
        {src:  require('../assets/images/ROYAL-017.jpg'), alt: 'Image 3'},
        {src:  require('../assets/images/IMG-20241009-WA0001.jpg'), alt: 'Image 4'},
        {src:  require('../assets/images/IMG-20241009-WA0008.jpg'), alt: 'Image 5'},
      ],
      autoSlideInterval: null,
    };
  },
  computed: {
    displayedMembers() {
      const start = Math.floor(this.currentSlide / 2) * 2;
      return this.teamMembers.slice(start, start + 2);
    },
    displayedImage() {
      return this.images[this.currentSlide];
    },
    isLastSlide() {
      return this.currentSlide === this.images.length - 1;
    },
    isFirstSlide() {
      return this.currentSlide === 0;
    }
  },
  methods: {
    nextSlide() {
      this.currentSlide = this.isLastSlide ? 0 : this.currentSlide + 1;
    },
    prevSlide() {
      this.currentSlide = this.isFirstSlide ? this.images.length - 1 : this.currentSlide - 1;
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(this.nextSlide, 3000); // Change slides every 3 seconds
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    }
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css'); /* Font Awesome */
.banner {
  background: url(../assets/images/IMG-20241009-WA0001.jpg) center no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  text-align: left;

}
.about-us {
  max-width: 1300px;
  margin: auto;
  padding: 10px;
  font-family: 'Roboto', sans-serif;

}



h2 {
  margin-top: 10px;
  color: #2980b9;
  font-size:16px;
  border-bottom: 1px solid #2980b9;
  padding-bottom: 5px;
}
h2 i {
  margin-right: 10px;
}

p {
font-size: 14px;
  margin-bottom: 5px;
  color: #34495e;
}

section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
}

section:hover {
  background-color: #f0f4f8;
  transform: translateY(-1px);
}

.team-photo, .image-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.team-slider, .image-slider {
  display: flex;

  align-items: center;
}

.slider-item {
  margin: 2px;
}

.slider-item img {
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Image shadow */
}


.team-buttons button, .image-buttons button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Transition effects */
}

.team-buttons button:hover, .image-buttons button:hover {
  background-color: #005f8c;
  transform: scale(1.05); /* Slight scale effect */
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #bbb;
  cursor: pointer;
}

.dot.active {
  background-color: #2980b9;
}

@media (max-width: 800px) {
  .about-us {
    padding: 20px;
  }

  .row {
    flex-direction: column;
  }

  .team-photo, .image-slider {
    flex-direction: column;
  }

  .slider-item {
    margin: 5px; /* Adjust margin for smaller screens */
  }
}
</style>