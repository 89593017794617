<template>
 <div class="row m-1">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="banner mt-2">
            <h3 class="box-heading">Contact Us</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex">
            <div class="container-fluid contact-info">
              <div class="row mt-4">
                <div class="col-md-8 mb-2">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="he">Contact Us</h4>
                      <form @submit.prevent=" submitForm">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label  for="name">Name</label>
                              <input
                                  class="form-control"
                                  placeholder="Enter your Name"
                                  v-model="contactDetails.firstname"
                                  id="name"
                                  type="text"

                              />
                              <div class=" showError">{{nameError}}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label >Last Name</label>
                              <input
                                  class="form-control"
                                  placeholder="Enter your Last Name"
                                  v-model="contactDetails.lastname"
                                  type="text"
                              />
                              <div class=" showError">{{lastnameError}}</div>
                            </div>

                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label  for="email">Email</label>
                              <input
                                  class="form-control"
                                  placeholder="Enter your Email"
                                  v-model="contactDetails.email"
                                  id="email"
                                  type="email"

                              />
                              <div class=" showError">{{emailError}}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label  for="phonenumber">Phone Number</label>
                              <input
                                  class="form-control"
                                  placeholder="Enter your Phonenumber"
                                  v-model="contactDetails.phonenumber"
                                  id="phonenumber"
                                  type="text"

                              />
                              <div class=" showError">{{phonenumberError}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label  for="message">Message</label>
                          <textarea
                              v-model="contactDetails.message"
                              id="message"
                              rows="5"

                              class="form-control"
                          ></textarea>
                          <div class=" showError">{{messageError}}</div>
                        </div>

                        <div class="d-flex links ">

                          <button  type="submit" style="border: none;background: none">
                            <a class="mr-3">
                              Submit
                            </a>
                          </button>



                        </div>
                        <success-popup v-if="showpopup" ref='popup' :name="nameclass" @clear="reset()" :message='response'>

                        </success-popup>
                      </form>
                    </div>
                  </div>


                </div>
                <div class="col-md-4">
                  <div class="card bg-tertiary">
                    <div class="card-body contact-details">
                      <h3 class="he">Contact Details</h3>
                      <div>
                        <div v-for="info in contactInfo" :key="info.type">
                          <p ><strong>{{ info.type }}</strong></p>
                          <template  v-if="info.type === 'Postal Address'">
                            <address class="ms-4" v-html="formatAddress(info.address)"></address>
                          </template>
                          <template v-else-if="info.type === 'Physical Address'">
                            <address class="ms-4" v-html="formatAddress(info.address)"></address>
                          </template>
                          <template v-else-if="info.type === 'WhatsApp or Call'">
                            <p class="ms-3" v-for="contact in info.contact" :key="contact">{{ contact }}</p>
                          </template>
                        </div>
                      </div>
                      <br />
                      <p><strong>Our Social Platforms</strong></p>
                      <ul class="social-icon">
                        <li>
                          <router-link to="#"><i class="fa fa-twitter"></i></router-link>
                        </li>
                        <li>
                          <router-link to="#"><i class="fa fa-whatsapp"></i></router-link>
                        </li>
                        <li>
                          <router-link to="#"><i class="fa fa-facebook"></i></router-link>
                        </li>
                        <li>
                          <router-link to="#"><i class="fa fa-linkedin"></i></router-link>
                        </li>
                      </ul>
                      <br /><br />
                      For more information about us you can send us an email at <router-link to="#">infor@southmews</router-link>  and we will be happy to respond to you. <br />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="map mt-2">
            <h3 class="box-heading w-25 " >Location</h3>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d948.9460842350406!2d30.9464132683842!3d-17.942210077937432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a18d5664aea7%3A0xd8799440d3b61261!2sSouthmews%20High%20School!5e0!3m2!1sen!2szw!4v1709543962468!5m2!1sen!2szw"
                class="width-100"
                height="450"
                style="border: 2px solid gray"
                allow="fullscreen"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>

  import SuccessPopup from "@/components/successPopup.vue";
  import {services} from "@/services/axios.js";
export default {
  name: "ContactUs",
  components: {SuccessPopup},
  data() {
    return {
      contactDetails :{
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        message: ""
      },


      showpopup:"",
      response:"",
      isSent:"",
      nameclass:"",


      nameError: '',
      lastnameError: '',
      phonenumberError: '',
      emailError:'',
      messageError: '',



      contactInfo: [
        {
          type: 'Postal Address',
          address: 'Southmews High school P. O. Box ......,<br/>Ushewokunze, Harare Zimbabwe',
        },
        {
          type: 'Physical Address',
          address: '19715 New Cerney Park,\nSaturday Retreat, Harare south,<br/>Zimbabwe',
        },
        {
          type: 'WhatsApp or Call',
          contact: ['+263 77 692 0588', '+263 77 640 3559'],
        },
      ],
    };
  },



  methods: {
    submitForm: async function () {
      this.clearErrors();
      let isValid = true;

      if (this.contactDetails.firstname=== '') {
        this.showError('name', 'Please enter your name.');
        isValid = false;
      }


      if (this.contactDetails.lastname=== '') {
        this.showError('lastname', 'Please enter your last Name.');
        isValid = false;
      }

      if (!this.isValidEmail(this.contactDetails.email)) {
        this.showError('email', 'Please enter a valid email address.');
        isValid = false;
      }

      if (this.contactDetails.phonenumber=== '') {
        this.showError('phonenumber', 'Please enter phone number.');
        isValid = false;
      }
      if (this.contactDetails.message=== '') {
        this.showError('message', 'Please type you message .');
        isValid = false;
      }


      if (isValid) {
        try {
          let response= await services.saveMessage(this.contactDetails)
             if (response){
               this.isSent=true
               this.showpopup=true
               this.response="message sent";
               this.nameclass='success';
               this.$router.push('/')
             }
             else{
               this.isSent=false
               this.showpopup=true
               this.response="message sending failed"
               this.nameclass='error';
               this.$router.push('/ContactUs')
          }

        }
        catch (error){

          console.log(error)

        }







      }

    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {
      this.nameError = '';
      this.lastnameError = '';
      this.phonenumberError = '';
      this.messageError = '';

      this.emailError = '';

    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    formatAddress(address) {
      return address.replace(/\n/g, '<br>');
    },
    reset() {
      this.contactDetails.name= '';
      this.contactDetails.lastname= '';
      this.contactDetails.phonenumber= '';
      this.contactDetails.email='';
      this.contactDetails.message= '';

    },
  },

};
</script>

  <style scoped>
  .banner {
    background: url(../assets/images/contactus2.webp) center no-repeat;
    background-size: cover;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 36px;
    text-align: left;

  }
form {
  padding-bottom: 16px;
}
.showError {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

.he {
  color:#2c3e50;
  font-size: 28px;
}
  .contact-details  {
    font-size: 16px;
    line-height: 25px;

  }
.map {
  flex-basis: 60%;
}

h2 {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 10px;
  color: #FFffff;
}
  h4 {

  color: #FFC000;
}

p {
  margin-bottom: 5px;
}

iframe {
  width: 100%;
  height: 450px;
  border: 2px solid gray;
}

.form-group {
  margin-bottom: 20px;

}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}



a[type="reset"] {
  background-color: #f44336;
  margin-left: 0;
}

  @media screen and (max-width: 320px) {

    .banner {
      background-size: 110px;
      height: 150px;
    }
    .box-heading{

      padding: 1px;

    }
    h3 {
      margin-bottom: 1px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 375px) {

    .banner {
      background-size: 330px;
      height: 150px;
    }
    .box-heading{

      padding: 1px;

    }
    h3 {
      margin-bottom: 1px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 425px) {

    .banner {
      background-size: 420px;
      height: 150px;
    }
    .box-heading{
      padding: 3px;

    }
    h3 {
      margin-bottom: 3px;
      font-size: 20px;
    }
  }

</style>