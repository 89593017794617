import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import AdmissionForm from"../views/AdmissionForm.vue";
import ContactUs from "../views/ContactUs.vue";
import History from "../views/History.vue";
import Teachers from "../views/Teachers.vue";
import feesStructure from "../views/feesStructure.vue";
import howToApply from "../views/howToApply.vue";
import SubjectsAndCourses from "../views/SubjectsAndCourses.vue";
import PrincipalMessage from "../views/PrincipalMessage.vue";
import StaffLogin from "../views/StaffLogin.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import StudentLogin from "../views/Studentlogin.vue";
import registrationView from "../views/registrationView.vue";
import StudentDashboard  from "../views/StudentDashboard";
import payments  from "../views/payments";
import acceptpayment  from "../views/acceptpayment.vue";
import admissionFormCont from "../views/admissionFormCont.vue"
import Parents from "@/views/Parents/Parents.vue";
import ParentsDashboard from "@/views/Parents/ParentsDashboard.vue";
import Results from "@/views/Parents/Results.vue";
// import DashBoard from "@/views/theAdmin/DashBoard.vue";
// import AdminDashboard from "@/views/theAdmin/AdminDashboard.vue";
// import AllStudents from "@/views/theAdmin/Students/AllStudents.vue";
// import AddStudent from "@/views/theAdmin/Students/AddStudent.vue";
// import BlogDetails from "@/views/theAdmin/Blog/BlogDetails.vue";
// import EditBlog from "@/views/theAdmin/Blog/EditBlog.vue";
// import AddBlog from "@/views/theAdmin/Blog/AddBlog.vue";
// import Blog from "@/views/theAdmin/Blog/Blog.vue";
import PageNotFound from "@/components/PageNotFound.vue";
// import EditStudent from "@/views/theAdmin/Students/EditStudent.vue";
// import AboutStudent from "@/views/theAdmin/Students/AboutStudent.vue";
// import AddResult from "@/views/theAdmin/Students/Results/AddResult.vue";
import AllEvents from "@/views/AllEvents.vue";
import EventDetails from "@/views/EventDetails.vue";
import test from "@/views/test.vue";
// import AddTeacher from "@/views/theAdmin/Teachers/AddTeacher.vue";
// import notification from "@/views/theAdmin/notification.vue";
const routes = [
  {
    path:"/admissionFormCont",
    name:"admissionFormCont",
    component:admissionFormCont,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/AdmissionForm",
    name: "AdmissionForm",
    component: AdmissionForm,
    meta: {
      title: "Admission"
    }
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: "Contact us"
    }
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    meta: {
      title: "About"
    }
  },

  {
    path: "/StudentLogin",
    name: "StudentLogin",
    component: StudentLogin,
    meta: {
      title: "Student login"
    }
  },
  {
    path: "/StaffLogin",
    name: "StaffLogin",
    component: StaffLogin,
    meta: {
      title: "staff login"
    }
  },

  {
    path: "/registration",
    name: "registrationView",
    component: registrationView,
    meta: {
      title: "registration"
    }
  },
  {
    path: "/History",
    name: "History",
    component: History,
    meta: {
      title: "History"
    }
  },
  {
    path: "/Teachers",
    name: "Teachers",
    component: Teachers,
    meta: {
      title: "Teachers"
    }
  },
  {
    path: "/feesStructure",
    name: "feesStructure",
    component: feesStructure,
    meta: {
      title: "Fees Structure"
    }
  },
  {
    path: "/howToApply",
    name: "howToApply",
    component: howToApply,
    meta: {
      title: "How to apply"
    }
  },
  {
    path: "/SubjectsAndCourses",
    name: "SubjectsAndCourses",
    component: SubjectsAndCourses,
    meta: {
      title: "Subject and Courses"
    }
  } ,
  {
    path: "/PrincipalMessage",
    name: "/PrincipalMessage",
    component: PrincipalMessage,
    meta: {
      title: "Principal message"
    }
  },
  {
    path: "/PrivacyPolicy",
    name: "/PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy"
    }
  },
  {
    path: "/StudentDashboard",
    name: "/StudentDashboard",
    component: StudentDashboard,
    meta: {
      title: "Student Dashboard"
    }
  },
  {
    path: "/payments",
    name: "/payments",
    component: payments,
    meta: {
      title: "Payment"
    }
  },
  {
    path: "/acceptpayment",
    name: "acceptpayment",
    component: acceptpayment,
    meta: {
      title: "Accept Payments"
    }
  },
    /*parents routes*/
  {
    path: "/Parents",
    name: "ParentsLogin",
    component: Parents,
    meta: {
      title: "Parents"
    }
  },
  {
    path: "/ParentsDashboard",
    name: "ParentsDashboard",
    component: ParentsDashboard,
    meta: {
      title: "Parent Dashboard"
    }
  },

  {
    path: "/Results",
    name: "Results",
    component: Results,
    meta: {
      title: "Results"
    }
  },
  {
    path: "/AllEvents",
    name: "AllEvents",
    component: AllEvents,
    meta: {
      title: "All events"
    }
  },{
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/EventDetails/:eventId",
    name: "Events",
    component: EventDetails,
    meta: {
      title: "Event Details"
    }
  },

  {
    path:'/:catchAll(.*)',
    name:"NotFound",
    component:PageNotFound,
    meta: {
      title: "Page Not Found"
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to)=>{
  document.title = `${to.meta.title} | SouthMews Collage`
})

export default router;
